/*
 *
 * scripts to be loaded at the bottom of the page
 *
 */
 
$(document).ready(function(){
  
  $('[data-toggle="tooltip"]').tooltip();

	function autoHeight($this){
			
			var scroll = $('body').scrollTop();
			
			if($this.closest('.modal').length) {
    			var modalScroll = $this.closest('.modal').scrollTop();
    		}
        	
        	
        	$this.css({'height' : 'auto' });
        	
        	$this.css({ 'height' : $this.prop('scrollHeight') + 'px', 'overflow-y' : 'hidden'});
        	
    		
          
        	$('body').scrollTop(scroll);
          
        	if($this.closest('.modal').length) {
        		console.log(modalScroll);
        		$this.closest('.modal').scrollTop(modalScroll);
          	
        	}
          
    }
    
    function autoHeightAll(){
    	$('textarea.subtle-input').each(function(){
    		autoHeight($(this));
    	});
    }
    
    autoHeightAll();
    
    $('textarea.subtle-input').on('keyup, blur', function(){
    	autoHeight($(this));
    });
    
    $('.modal').on('shown.bs.modal', function() {
    	autoHeightAll()
    });
	
	$('#search-text').on('keyup', function(){
		
		var input = $('#search-text').val();
		
		var url = $(this).closest('form').data('ajax');
		
		$.ajax({
			url : url + '?q=' + input,
			type: 'GET',
			dataType: 'text'
		}).done(function($html){
			
			var $returned = $.trim($($html).text());
			
			var $current = $.trim($('#activity-container').text());
			
			if($current != $returned){
				$('#activity-list').replaceWith($html);
			}
			
		});
		
	});
	
	
	$('body').on('click', '.j-switch', function(e){
		
		var $this = $(this);
		
		var url = $this.data('switch');
		
		$.ajax({
			url : url,
			type: 'GET'
		}).done(function($html){
			
			$this.replaceWith($html);
			
		});
		
		e.preventDefault();
	});
	

	
   
   $('body').on('submit', '.flip-form', function(e){
   	
   	var $this = $(this);
   	var data = $this.serialize();
   	var url = $this.attr('action');
   	
   	$(this).find('button').attr('disabled', 'disabled');
   	
   	$this.closest('.flip-stack-inner').toggleClass('flipped');
   	
   	$.ajax({
   		url: url,
   		data:data,
   		type: 'POST',
   		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
   		
   	}).done(function(){
   		
   		$this.find('button').removeAttr('disabled');
   		
   	});
   	
   	e.preventDefault();
   	
   });
    	
    
	
	// auto save
	if($('.auto-save').length){
	
		 // if form changes...
		 $('.auto-save').each(function(e){
		 	
		 	$(this).data('changed', false)
		 	.on('submit', function(){
	    		return false;
	    	});
	    	
		 });
	     
	     $('.auto-save').on('change textInput input submit', function(){
	          
	          $(this).data('changed', true);
	          
	          if($('#save-notice').length) {
	          		$('#save-notice').text('Saving...');
	          }
	          
	     });
	        
	      // ...save it
	        var save = function autosave() {
	        
	         $('.auto-save').each(function(){
	         
	           if($(this).data('changed')) {
	               
	              var $this = $(this) ;
	              
	              $.ajax({
	                      url: $this.attr('action'),
	                      data: $this.serialize(),
	                      type: 'POST'
	              }).done(function(){
	                
	                $this.data('changed', false);
	                
	                if($('#save-notice').length) {
	          	
			          	$('#save-notice').text('Saved');
			          	
			          }
	               
	              });
	                
	           }
	           
	         });
	            
	        };
	      
	      // and check every couple seconds
	      $(document).ready(function () {
	        setInterval(save,2000);
	      });

	 }
	 
	 //end auto save
	 
	 
	 $("[data-paired-to]").on('change textInput input', function(){
	 	
	 	var val = $(this).val();
	 	
	 	var pairedTo = $(this).data('paired-to');
	 	
	 	if($(pairedTo).length) {
	 		$(pairedTo).text(val);
	 	}

	 });
	
	
});